import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import { Box, Text, Button, Heading } from 'theme-ui'
import { gsap } from "gsap"
import { Draggable } from "gsap/all"
import { InertiaPlugin } from "gsap/InertiaPlugin";
if (typeof window !== "undefined") {
    gsap.registerPlugin(Draggable, InertiaPlugin); 
  }

const GalleryComp = ({ galleryArray, title, description }) => {
    const [isDragged, setIsDragged] = useState(false)

    

    useEffect(() => {
        console.log(-document.querySelector(".slider-cont").offsetWidth, document.querySelector(".cont").offsetWidth);
        Draggable.create(".slider-cont", {
            type: "x",
            edgeResistance: 0.5,
            inertia: true,
            allowContextMenu: true,
            onPress: () => {
                gsap.to(".slide-image", { scale: 0.9, ease: 'slow' });
                gsap.to(".slide-image .image-title", { opacity: 1, ease: 'sine' });
                gsap.to(".slide-image .gatsby-image-wrapper", { scale: 1.15, opacity: 0.5 })
            },
            onRelease: () => {
                gsap.to(".slide-image", { scale: 0.98,});
                gsap.to(".slide-image .image-title", { opacity: 0, });
                gsap.to(".slide-image .gatsby-image-wrapper", { scale: 1, opacity: 1, })
            },
            onThrowComplete: function () { console.log(this.x) },
            bounds: {
                minX: -document.querySelector(".slider-cont").offsetWidth + document.querySelector(".cont").offsetWidth,
                maxX: 0
            }
        });
    })



    const handleImage = data => data.map(image => <Box
        className="slide-image"
        sx={{
            width: ['80vw','50vw'],
            height: '100%',
            mx: 3,
            borderRadius: 10,
            overflow: 'hidden',
            flexShrink: 0,
            // transition: '0.3s all ease-in-out',
            // transform: isDragged ? 'size(0.9)' : 'size(1)',
            '& .gatsby-image-wrapper': {
                height: '100%',
            }

        }}>
        <Text 
        className="image-title"
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            opacity: 0,
            transform: 'translate(-50%, -50%)',
            color: 'white',
            zIndex: 2,
            textAlign: 'center',
            fontSize: 3,
            letterSpacing: 1,
            fontWeight: 400,
            width: '95%',
        }}>{image.title || "PrimeBerry image"}</Text>
        <Img fluid={image.fluid}>

        </Img>
    </Box>)
    return (
        <Box
            sx={{
                pt: [0,0,4],
                pb: [0,0,3],
                mb: [5,0],
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: ['column','row','row'],
                    p: [0,0,4],
                    mb: [0,3,3],
                    px: [3,4,5],
                    alignItems: ['center','inherit','inherit'],

                }}>
                <Box
                    className="galleryLogo"
                    sx={{
                        willChange: 'transform',
                        pl: [0,2,0],
                        pr: [0,4,4],
                        mb: [3,0,4],
                    }}>
                        
                    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5838 46.5676C10.3841 42.4558 13.4839 33.2453 13.1887 31.765C12.8935 30.2847 9.49839 30.6137 9.49839 30.6137C7.9015 31.0736 6.21327 30.9364 4.69316 30.2232C3.17306 29.5101 1.90521 28.2604 1.0845 26.6663C-2.75341 19.1005 4.33196 12.1926 10.8269 13.6729C10.8269 13.6729 8.16988 5.44923 13.1887 1.83081C13.1887 1.83081 20.4217 -4.58366 30.1641 6.60055L33.2639 9.56107C35.1606 7.83784 37.533 6.89676 39.9803 6.89676C42.4276 6.89676 44.7999 7.83784 46.6966 9.56107C52.6011 15.9755 45.5157 24.6926 43.5968 26.0084C44.8128 26.6364 45.8927 27.5498 46.7624 28.6861C47.6321 29.8224 48.2711 31.1547 48.6356 32.5918C49.0001 34.0289 49.0815 35.5368 48.8742 37.0122C48.6669 38.4877 48.1759 39.8957 47.4347 41.14C46.9058 42.4364 46.1098 43.5754 45.1113 44.4643C44.1128 45.3531 42.9401 45.9668 41.6885 46.2553C40.437 46.5438 39.142 46.4991 37.9089 46.1246C36.6758 45.7502 35.5395 45.0567 34.5924 44.1005C33.5167 42.8814 32.121 42.075 30.6069 41.7979C29.1308 42.1268 23.5215 51.1729 16.5838 46.5676Z" fill="white" />
                    </svg>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: ['column','row','row'],
                        justifyContent: ['center','space-between','space-between'],
                        alignItems: ['center','inherit', 'none'],
                        width: '100%',
                        pr: [0,0,3],
                        textAlign: ['center','auto','inherit'],
                    }}>

                    <Heading
                    className="galleryTitle"
                        sx={{
                            color: 'white',
                            width: ['80%','40%',300],
                            fontSize: [6,6,6],
                        }}>
                        {title}
                    </Heading>

                    {description && <Box
                        sx={{
                            display: 'flex',
                            pt: 3,

                        }}>

                        <Text
                            sx={{
                                color: 'white',
                                px: [3,4,2],
                                mb: [3,0,0]
                            }}>
                            {description}
                        </Text>
                        <Box>
                            <svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M39.3536 4.35355C39.5488 4.15829 39.5488 3.84171 39.3536 3.64645L36.1716 0.464466C35.9763 0.269204 35.6597 0.269204 35.4645 0.464466C35.2692 0.659728 35.2692 0.976311 35.4645 1.17157L38.2929 4L35.4645 6.82843C35.2692 7.02369 35.2692 7.34027 35.4645 7.53553C35.6597 7.7308 35.9763 7.7308 36.1716 7.53553L39.3536 4.35355ZM0 4.5H39V3.5H0V4.5Z" fill="white" />
                            </svg>
                        </Box>
                    </Box>}
                </Box>
            </Box>


            <Box
                className="cont"
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: ['100vw','40vw'],
                    overflow: 'hidden',
                    position: 'relative',
                }}>
                <Box
                    className="slider-cont"
                    onMouseEnter={() => gsap.to(".slide-image", { scale: 0.98 })}
                    onMouseLeave={() => gsap.to(".slide-image", { scale: 1 })}
                    sx={{
                        position: 'absolute',
                        display: 'inline-flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        height: '100%',
                        my: [4,0],
                        '& .slide-image:first-of-type': {
                            ml: [0,6],
                        },
                        '& .slide-image:last-of-type': {
                            mr: 6,
                        },
                        '& .slide-image + .slide-image': {
                            ml: 4,
                        }
                    }}>
                    {handleImage(galleryArray)}
                </Box>


                {/* <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        mx: 3,
                        overflow: 'hidden',
                        '& .gatsby-image-wrapper': {
                            height: '100%'

                        }

                    }}>
                    <Img fluid={galleryImage2.fluid}>

                    </Img>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        mx: 3,
                        borderRadius: 10,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',

                    }}>
                    <Img fluid={galleryImage3.fluid} imgStyle={{ objectFit: 'cover' }} style={{ height: '100%', width: '100%' }}>
                    </Img>


                </Box> */}
            </Box>
            {/* <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItens: 'center',
                    px: 6,
                    pb: 40,

                }}>
                <Text
                    sx={{
                        fontSize: 2,
                        color: 'white',

                    }}>
                    {galleryImage1.title}
                </Text>
                <Text
                    sx={{
                        fontSize: 2,
                        color: 'white',

                    }}>
                    {galleryImage2.title}
                </Text>
                <Text
                    sx={{
                        fontSize: 2,
                        color: 'white',

                    }}>
                    {galleryImage3.title}
                </Text>
            </Box> */}
        </Box>
    )
}

export default GalleryComp